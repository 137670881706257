import ProductId from '../../../components/product-id/ProductId';
import { IProduct } from '../../../shared/interfaces/IProduct';

const ProductIdColBody = (product: IProduct) => {
  return (
    <ProductId
      productId={product.product_cid}
      requestedProductId={product.other_request_data?.product_id}
    />
  );
};

export default ProductIdColBody;
