import ProductName from '../../../components/product-name/ProductName';
import { PCFRequestStatus } from '../../../shared/enums/pcf-request';
import { IProduct } from '../../../shared/interfaces/IProduct';

const ProductNameColBody = (product: IProduct) => {
  // If the product is completed, prepare href for the product link
  const href =
    product.status === PCFRequestStatus.Complete ? `/products/${product.product_id}` : '';

  return (
    <ProductName
      productName={product.product_name}
      requestedProductName={product.other_request_data?.product_name}
      href={href}
    />
  );
};

export default ProductNameColBody;
