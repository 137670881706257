import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { IProductDetails } from '../../pages/product-details/interfaces/ProductDetails.interface';
import AmplifyApiService from '../../services/AmplifyApiService';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import { IProduct } from '../../shared/interfaces/IProduct';

export type ProductsParams = {
  status?: string;
  region?: string;
  od?: string;
  search?: string;
};

export type GetProductDetailsParams = {
  productId: string;
  options?: UseQueryOptions<IProductDetails, Error>;
};

type ExportPcfsProps = {
  productIds: number[];
};

export type ExportPcfsResponse = {
  fileUrl: string;
  successCount: number;
  errorCount: number;
};

const amplifyApiService = new AmplifyApiService();

export const useListProducts = (params: ProductsParams = {}): UseQueryResult<IProduct[]> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'product',
    init: {
      queryStringParameters: params,
    },
  };

  const query = useQuery<IProduct[], Error>({
    queryKey: ['listProducts', params],
    queryFn: async (): Promise<IProduct[]> => {
      const response = await amplifyApiService.get<{ products: IProduct[] }>({
        config: amplifyApiConfig,
      });

      return response.products;
    },
  });

  return query;
};

export const useGetProductDetails = (
  params: GetProductDetailsParams,
): UseQueryResult<IProductDetails, Error> => {
  const { productId } = params;

  const amplifyApiConfigForJsonDownloadLink: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: `product/${productId}`,
    init: {},
  };

  const query = useQuery<IProductDetails, Error>({
    queryKey: ['getProductDetails', params],
    queryFn: async (): Promise<IProductDetails> => {
      // First get JSON download link from PACIFIC API
      const response = await amplifyApiService.get<IProductDetails>({
        config: amplifyApiConfigForJsonDownloadLink,
      });

      return response;
    },
    ...params.options,
  });

  return query;
};

export const useExportPcfs = (
  options: UseMutationOptions<ExportPcfsResponse, Error, ExportPcfsProps> = {},
): UseMutationResult<ExportPcfsResponse, Error, ExportPcfsProps> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'request',
    init: {
      queryStringParameters: {
        action: 'download-csv',
      },
    },
  };

  const mutation = useMutation<ExportPcfsResponse, Error, ExportPcfsProps>({
    mutationFn: async (productIds: ExportPcfsProps) => {
      return await amplifyApiService.post<ExportPcfsProps, ExportPcfsResponse>({
        config: amplifyApiConfig,
        body: productIds,
      });
    },
    ...options,
  });

  return mutation;
};
